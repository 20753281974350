
import { useRouter } from "vue-router"
import OrganizationService from "@/services/OrganizationService"

export default {
  setup() {
    const router = useRouter()
    const organizationService = OrganizationService.getInstance()

    function onCreateOrganization(organization: any, company: any) {
      organizationService.addOrganization(organization, company.id).then((result: string) => {
        router.push({ name: "organizationProfile", params: { organization_id: result } })
      })
    }

    function onCancel() {
      router.go(-1)
    }

    return {
      onCreateOrganization,
      onCancel,
    }
  },
}
